import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HOME } from '../routes/ROUTES';

const TestRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(HOME);
  }, []);

  return <div>TestRedirect</div>;
};

export default TestRedirect;
