import { useState } from 'react';
import { useParams } from 'react-router-dom';

import Main from '../../components/Main';
import ViewNotFound from '../../components/ViewNotFound';
import { useAppData } from '../../context/appDataContext';
import ContentsHeader from './components/ContentsHeader';

import Payments from './components/Payments';
import PolicyOverview from './components/PolicyOverview';
import UpdatePaymentMethod from './components/UpdatePaymentMethod';
import VehicleHeader from './components/VehicleHeader';

const PolicyView = () => {
  const { policyId } = useParams();
  const { policies } = useAppData();

  const policy = policies.find(policy => policy.policyId === policyId);

  console.log('Kommer in i policy', policies);

  const [showEditPaymentMethod, setShowEditPaymentMethod] =
    useState<boolean>(false);

  const showUpdatePaymentMethod = () => setShowEditPaymentMethod(true);
  const hideUpdatePaymentMethod = () => setShowEditPaymentMethod(false);

  const getHeader = (): JSX.Element | null => {
    if (!policy) return null;

    switch (policy.productSpecifics.tag) {
      case 'ContentsPolicy':
        return (
          <ContentsHeader contentsPolicySpecifics={policy.productSpecifics} />
        );
      case 'VehiclePolicy':
        return (
          <VehicleHeader vehiclePolicySpecifics={policy.productSpecifics} />
        );
    }
  };

  return policy ? (
    <>
      {getHeader()}
      <Main>
        <PolicyOverview
          policy={policy}
          showUpdatePaymentMethod={showUpdatePaymentMethod}
        />
        <Payments payments={policy.payments} />
      </Main>
      {showEditPaymentMethod && (
        <UpdatePaymentMethod
          ledgerId={policy.ledgerId}
          open={showEditPaymentMethod}
          hideUpdatePaymentMethod={hideUpdatePaymentMethod}
        />
      )}
    </>
  ) : (
    <ViewNotFound />
  );
};

export default PolicyView;
