import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { IconName } from '../types/icon-types';
import Icon from './Icon';

type MenuItemWrapper = {
  fontColor: string;
  hideMenu: () => void;
  iconName: IconName;
  iconRotation?: number;
  text: string;
  to: string;
};

const MenuItem = ({
  text,
  to,
  iconName,
  iconRotation = 0,
  hideMenu,
  fontColor,
}: MenuItemWrapper) => {
  const handleClick = () => hideMenu();

  return (
    <li onClick={handleClick}>
      <MenuLink to={to} fontColor={fontColor}>
        <MenuContentWrapper>
          <Icon iconName={iconName} />
          <span>{text}</span>
        </MenuContentWrapper>
      </MenuLink>
    </li>
  );
};

export default MenuItem;

const MenuLink = styled(Link)<{ fontColor: string }>`
  font-size: 1.4rem;
  font-weight: 500;
  color: ${({ fontColor }) => fontColor};
  text-decoration: none;
`;

const MenuContentWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  span {
    white-space: nowrap;
  }
`;
