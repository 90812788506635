import { createContext, useContext, useReducer } from 'react';
import { AxiosError } from 'axios';
import axiosRetry from 'axios-retry';

import axiosInstance from '../api/axiosInstance';
import { Policy } from '../types/policy-types';
import { Person } from '../types/customer-types';

type AppData = {
  person: Person;
  policies: Policy[];
};

type AppDataContextType = {
  getPolicies?: () => void;
  person: Person | null;
  policies: Policy[];
  selectedPolicy: string | null;
};

type AppDataContextProps = {
  children: JSX.Element | JSX.Element[];
};

enum AppDataActionsKind {
  GET_APP_DATA = 'GET_APP_DATA',
  SET_SELECTED_POLICY = 'SET_SELECTED_POLICY',
}

const initialState: AppDataContextType = {
  person: null,
  policies: [],
  selectedPolicy: null,
};

type Actions = { type: AppDataActionsKind.GET_APP_DATA; payload: AppData };

const AppDataContext = createContext<AppDataContextType>(initialState);

const appDataReducer = (state: AppDataContextType, action: Actions) => {
  switch (action.type) {
    case AppDataActionsKind.GET_APP_DATA:
      return {
        ...state,
        person: action.payload.person,
        policies: action.payload.policies,
      };
    default:
      return state;
  }
};

const AppDataProvider = ({ children }: AppDataContextProps) => {
  const [state, dispatch] = useReducer(appDataReducer, initialState);

  const getPolicies = async () => {
    axiosRetry(axiosInstance, { retries: 3 });

    try {
      const response = await axiosInstance.get('');
      const payload: AppData = response.data;

      dispatch({
        type: AppDataActionsKind.GET_APP_DATA,
        payload,
      });
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
    }
  };

  return (
    <AppDataContext.Provider
      value={{
        person: state.person,
        policies: state.policies,
        selectedPolicy: state.selectedPolicy,
        getPolicies,
      }}
    >
      {children}
    </AppDataContext.Provider>
  );
};

const useAppData = () => useContext(AppDataContext);

export { AppDataProvider, useAppData };
