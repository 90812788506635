import styled from 'styled-components';
import { RemoveScroll } from 'react-remove-scroll';
import { animated, useTransition } from 'react-spring';

import SectionBox from './SectionBox';

type PopupProps = {
  open: boolean;
  children: JSX.Element[];
  theme: 'default' | 'green';
};

const Popup = ({ open, theme = 'default', children }: PopupProps) => {
  const ANIMATION_DURATION = 200;

  const themes = {
    default: {
      fontColor: 'inherit',
      backgroundColor: '#fff',
    },
    green: {
      fontColor: '#3c763d',
      backgroundColor: '#dff0d8',
    },
  };

  const outerTransition = useTransition(open, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0, delay: ANIMATION_DURATION },
    config: {
      duration: ANIMATION_DURATION,
    },
  });

  const innerTransition = useTransition(open, {
    from: { transform: 'scale(0)' },
    enter: { transform: 'scale(1)', delay: ANIMATION_DURATION },
    leave: { transform: 'scale(0)' },
    config: {
      duration: ANIMATION_DURATION,
    },
  });

  return (
    <RemoveScroll>
      {outerTransition(
        (styles, item) =>
          item && (
            <PopupBackground open={open} style={styles}>
              {innerTransition(
                (styles, item) =>
                  item && (
                    <PopupContainer
                      theme={themes[theme]}
                      open={open}
                      style={styles}
                    >
                      {children}
                    </PopupContainer>
                  )
              )}
            </PopupBackground>
          )
      )}
    </RemoveScroll>
  );
};

export default Popup;

const PopupBackground = styled(animated.div)<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 4rem 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
`;

const PopupContainer = styled(SectionBox)<{
  open: boolean;
  theme: { backgroundColor: string; fontColor: string };
}>`
  box-shadow: 0 0.125rem 0.62rem rgba(0, 0, 0, 0.3);
  background-color: ${({ theme }) => theme.backgroundColor};
  color: ${({ theme }) => theme.fontColor};
  max-width: 32rem;
`;
