import React from 'react';
import ReactDOM from 'react-dom/client';
import { CriiptoVerifyProvider } from '@criipto/verify-react';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';

import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const instance = createInstance({
  urlBase: 'https://eirforsakring.matomo.cloud',
  siteId: 4,
});

root.render(
  <React.StrictMode>
    <CriiptoVerifyProvider
      domain={`${process.env.REACT_APP_IDENTITY_CONFIG_AUTHORITY ?? ''}`}
      clientID={process.env.REACT_APP_IDENTITY_CONFIG_CLIENT_ID ?? ''}
      // redirectUri={window.location.href}
      sessionStore={localStorage}
    >
      <MatomoProvider value={instance}>
        <App />
      </MatomoProvider>
    </CriiptoVerifyProvider>
  </React.StrictMode>
);
