import Header from '../../../components/Header';
import HomeLink from '../../../components/HomeLink';
import { ContentsPolicySpecifics } from '../../../types/policy-types';

type ContentsHeaderProps = {
  contentsPolicySpecifics: ContentsPolicySpecifics;
};

const ContentsHeader = ({ contentsPolicySpecifics }: ContentsHeaderProps) => {
  return (
    <Header>
      <HomeLink />
      <h1>{contentsPolicySpecifics.address}</h1>
    </Header>
  );
};

export default ContentsHeader;
