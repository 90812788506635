import Icon from '../../../components/Icon';
import { VehiclePolicySpecifics } from '../../../types/policy-types';

type PolicyVehicleItemHeaderProps = {
  vehiclePolicySpecifics: VehiclePolicySpecifics;
};

const PolicyVehicleItemHeader = ({
  vehiclePolicySpecifics,
}: PolicyVehicleItemHeaderProps) => {
  return (
    <>
      <Icon iconName='faCar' color='#00c2ff' />
      <span>
        {vehiclePolicySpecifics.registrationNumber.toLocaleUpperCase()}
      </span>
    </>
  );
};

export default PolicyVehicleItemHeader;
