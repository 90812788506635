import Icon from '../../../components/Icon';
import { ContentsPolicySpecifics } from '../../../types/policy-types';

type PolicyContentsItemHeaderProps = {
  contentsPolicySpecifics: ContentsPolicySpecifics;
};

const PolicyContentsItemHeader = ({
  contentsPolicySpecifics,
}: PolicyContentsItemHeaderProps) => {
  return (
    <>
      <Icon iconName='faHouseChimney' color='#00c2ff' />
      <span>{contentsPolicySpecifics.address}</span>
    </>
  );
};

export default PolicyContentsItemHeader;
