import Header from '../../../components/Header';
import HomeLink from '../../../components/HomeLink';
import { VehiclePolicySpecifics } from '../../../types/policy-types';

type VehicleHeaderProps = {
  vehiclePolicySpecifics: VehiclePolicySpecifics;
};

const VehicleHeader = ({ vehiclePolicySpecifics }: VehicleHeaderProps) => {
  return (
    <Header>
      <HomeLink />
      <h1>{vehiclePolicySpecifics.registrationNumber.toLocaleUpperCase()}</h1>
    </Header>
  );
};

export default VehicleHeader;
