import styled from 'styled-components';

import background from '../../images/login-bg.jpg';
import logo from '../../images/lin.png';
import SectionBox from '../../components/SectionBox';
import Button from '../../components/Button';
import checkIfMobile from '../../utils/checkIfMobile';
import bankidLogo from '../../images/BankID_logo.png';

const LoginView = () => {
  const handleLoginClick = (useSameDevice: boolean) => (): void => {
    const acrValue = useSameDevice
      ? 'urn:grn:authn:se:bankid:same-device'
      : 'urn:grn:authn:se:bankid:another-device:qr';

    window.location.href = `https://${process.env.REACT_APP_IDENTITY_CONFIG_AUTHORITY}/oauth2/authorize?scope=openid&client_id=${process.env.REACT_APP_IDENTITY_CONFIG_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_IDENTITY_CONFIG_REDIRECT_URI}&response_type=${process.env.REACT_APP_IDENTITY_CONFIG_RESPONSE_TYPE}&response_mode=${process.env.REACT_APP_IDENTITY_CONFIG_RESPONSE_MODE}&nonce=ecnon-b6aa22e4-64f0-424c-bc82-ec4ee348c158&acr_values=${acrValue}`;
  };

  return (
    <LoginContainer>
      <TopList>
        <img src={logo} alt='lin Försäkring' />
      </TopList>
      <Overlay>
        <LoginSection>
          <h1>Logga in</h1>
          <BankIdLogoWrapper>
            <img src={bankidLogo} alt='BankID' />
          </BankIdLogoWrapper>
          <ButtonContainer>
            {checkIfMobile() ? (
              <>
                <Button
                  variant='primary'
                  size='large'
                  onClick={handleLoginClick(true)}
                >
                  Mobilt BankID
                </Button>
                <Button
                  variant='primary'
                  size='large'
                  onClick={handleLoginClick(false)}
                >
                  BankID på annan enhet
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant='primary'
                  size='large'
                  onClick={handleLoginClick(false)}
                >
                  Mobilt BankID
                </Button>
                <Button
                  variant='primary'
                  size='large'
                  onClick={handleLoginClick(true)}
                >
                  BankID på denna enhet
                </Button>
              </>
            )}
          </ButtonContainer>
        </LoginSection>
      </Overlay>
    </LoginContainer>
  );
};

export default LoginView;

const LoginContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
`;

const TopList = styled.header`
  background-color: #fff;
  display: flex;
  justify-content: center;
  padding: 0.75rem;

  img {
    width: 4rem;
    height: auto;
  }
`;

const Overlay = styled.main`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;
`;

const LoginSection = styled(SectionBox)`
  max-width: 30rem;
`;

const BankIdLogoWrapper = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: auto;
    height: 7rem;
  }
`;
