import styled from 'styled-components';

import SectionBox from '../../../components/SectionBox';
import NamedItem from '../../../NamedItem';
import { Policy } from '../../../types/policy-types';
import formatDate from '../../../utils/formatDate';
import ProductSpecificsContent from './ProductSpecificsContents';
import ProductSpecificsVehicle from './ProductSpecificsVehicle';

type PolicyOverviewProps = {
  policy: Policy;
  showUpdatePaymentMethod: () => void;
};

const PolicyOverview = ({
  policy,
  showUpdatePaymentMethod,
}: PolicyOverviewProps) => {
  const handleUpdatePaymentMethodClick = () => showUpdatePaymentMethod();

  const valueTranslations = {
    Autogiro: 'Autogiro via Trustly',
    CreditCard: 'Kortbetalning via Nets',
    Monthly: 'Per månad',
    Yearly: 'Per år',
  };

  const getProductSepcificInformation = (): JSX.Element => {
    switch (policy.productSpecifics.tag) {
      case 'ContentsPolicy':
        return (
          <ProductSpecificsContent
            contentsPolicySpecifics={policy.productSpecifics}
          />
        );
      case 'VehiclePolicy':
        return (
          <ProductSpecificsVehicle
            vehiclePolicySpecifics={policy.productSpecifics}
          />
        );
    }
  };

  return (
    <>
      <PolicySection>
        <ContentWrapper>
          <NamedItem>
            <span>Försäkringsnummer</span>
            {policy.policyNumber}
          </NamedItem>
          <NamedItem>
            <span>Avtalsperiod</span>
            {formatDate(new Date(policy.startDay))} -{' '}
            {formatDate(new Date(policy.endDay))}
          </NamedItem>
          {getProductSepcificInformation()}
          <NamedItem>
            <span>Pris</span>
            {policy.yearlyPremium.toLocaleString('sv-SE', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}{' '}
            kr/år
          </NamedItem>
          <NamedItem>
            <span>Betalningsperiod</span>
            {valueTranslations[policy.paymentPeriod]}
          </NamedItem>
          <NamedItem>
            <span>Betalningmetod</span>
            <UpdateWrapper>
              {valueTranslations[policy.paymentMethod]}
              <button onClick={handleUpdatePaymentMethodClick}>Ändra</button>
            </UpdateWrapper>
          </NamedItem>
          <hr />
          <ContactText>
            Vill du ändra något i din försäkring, vänligen kontakta vår
            kundtjänst på e-post{' '}
            <a href='mailto:info@linforsakring.se'>info@linforsakring.se</a>{' '}
            eller telefon{' '}
            <NoWrap>
              <a href='tel:+46852027969'>08 - 520 279 69</a>
            </NoWrap>
            .
          </ContactText>
        </ContentWrapper>
      </PolicySection>
      <Links>
        <li>
          <a
            href={`${process.env.REACT_APP_POLICY_LETTER_URL}/p/${policy.policyId}`}
            target='_blank'
            rel='noreferrer'
          >
            Visa försäkringsbrev
          </a>
        </li>
        <li>
          <a
            href={process.env.REACT_APP_TERMS_AND_CONDITIONS_URL}
            target='_blank'
            rel='noreferrer'
          >
            Gå till fullständiga villkor
          </a>
        </li>
      </Links>
    </>
  );
};
export default PolicyOverview;

const PolicySection = styled(SectionBox)``;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const UpdateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    background-color: transparent;
    border: none;
    color: #252555;
    font-size: 0.8rem;
    font-weight: 500;
    text-decoration: underline;
    padding: 0;
    cursor: pointer;
  }
`;

const ContactText = styled.p`
  font-size: 0.8rem;
`;

const Links = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: 0;
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;
