import axios from 'axios';

const axiosInstance = axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  baseURL:
    'https://41gkonxvy9.execute-api.eu-north-1.amazonaws.com/default/my-site/person',
  headers: {
    'Content-Type': 'application/json',
    authorization: `Bearer `,
  },
});

export default axiosInstance;
