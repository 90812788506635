import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { HOME } from '../routes/ROUTES';
import Icon from './Icon';

const HomeLink = () => {
  return (
    <StyledLink to={HOME}>
      <span>
        <Icon iconName='faChevronLeft' />
      </span>{' '}
      Hem
    </StyledLink>
  );
};

export default HomeLink;

const StyledLink = styled(Link)`
  color: #252555;
  font-weight: 500;

  span {
    font-size: 0.75rem;
  }
`;
