import styled from 'styled-components';
import Button from '../../../components/Button';
import Popup from '../../../components/Popup';
import PopupHeader from '../../../components/PopupHeader';

type UpdatePaymentMethodProps = {
  hideUpdatePaymentMethod: () => void;
  ledgerId: string;
  open: boolean;
};

const UpdatePaymentMethod = ({
  ledgerId,
  open,
  hideUpdatePaymentMethod,
}: UpdatePaymentMethodProps) => {
  const handleUpdatePaymentMethodClick =
    (provider: 'nets' | 'trustly') => () => {
      window.open(
        `${process.env.REACT_APP_POLICY_LETTER_URL}/reattach/${provider}/${ledgerId}`,
        '_blank'
      );
    };

  return (
    <Popup open={open} theme='default'>
      <PopupHeader hidePopup={hideUpdatePaymentMethod}>
        <H4>Uppdatera betalningsmetod</H4>
      </PopupHeader>
      <main>
        <p>
          För att uppdatera betalningsmetod för försäkringen klicka på någon av
          knapparna. När du klickar på knappen kommer ett nytt fönster att
          öppnas och du kommer omdirigeras till Nets för att betala din
          försäkring med kort och till Trustly för att betala din försäkring med
          Autogiro.
        </p>
        <ButtonWrapper>
          <Button
            variant='primary'
            size='large'
            onClick={handleUpdatePaymentMethodClick('nets')}
          >
            Kortbetalning via Nets
          </Button>
          <Button
            variant='primary'
            size='large'
            onClick={handleUpdatePaymentMethodClick('trustly')}
          >
            Autogiro via Trustly
          </Button>
        </ButtonWrapper>
      </main>
    </Popup>
  );
};

export default UpdatePaymentMethod;

const H4 = styled.h4`
  line-height: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
`;
