import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightFromBracket,
  faCar,
  faChevronLeft,
  faChevronRight,
  faGears,
  faHouse,
  faHouseChimney,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircleDot,
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-regular-svg-icons';

import { IconName } from '../types/icon-types';

type IconProps = {
  color?: string;
  iconName: IconName;
  size?:
    | '1x'
    | 'xs'
    | 'lg'
    | 'sm'
    | '2x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x';
};

const Icon = ({ iconName, color = 'inherit', size = '1x' }: IconProps) => {
  const icons = {
    faArrowRightFromBracket,
    faCar,
    faChevronLeft,
    faChevronRight,
    faCircleDot,
    faCircleCheck,
    faCircleXmark,
    faGears,
    faHouse,
    faHouseChimney,
    faUser,
    faXmark,
  };

  return <FontAwesomeIcon color={color} icon={icons[iconName]} size={size} />;
};

export default Icon;
