import styled from 'styled-components';

const Header = styled.header`
  max-width: 40rem;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  margin: auto;

  h1,
  h2,
  h3 {
    /* text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.6); */
    text-shadow: 1px 1px 10px #000;
    color: #fff;
    line-height: 1.2;

    span {
      font-size: 1.6rem;
    }
  }

  @media (min-width: 40rem) {
    h1 {
      font-size: 4rem;
    }

    h3 {
      font-size: 1.8rem;
    }
  }
`;

export default Header;
