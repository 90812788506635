import { useState } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import Main from '../../components/Main';

import SectionBox from '../../components/SectionBox';
// import UpdateEmail from './components/UpdateEmail';
import NamedItem from '../../NamedItem';
import { useAppData } from '../../context/appDataContext';
import HomeLink from '../../components/HomeLink';

const ProfileView = () => {
  const { person } = useAppData();

  // const [showUpdateEmail, setShowUpdateEmail] = useState(false);

  // const handleUpdateEmailClick = () => {
  //   setShowUpdateEmail(true);
  // };
  // const hideUpdateEmail = () => setShowUpdateEmail(false);

  return (
    person && (
      <>
        <Header>
          <HomeLink />
          <h1>Min profil</h1>
        </Header>
        <Main>
          <ProfileWrapper>
            <AddressWrapper>
              <Bold>
                {person.firstName} {person.lastName}
              </Bold>
              <span>{person.address}</span>
              <span>
                {person.postCode} {person.city}
              </span>
            </AddressWrapper>
            <NamedItem>
              <span>E-post</span>
              {person.email}
              {/* <UpdateWrapper>
                {person.email}
                <button onClick={handleUpdateEmailClick}>Ändra</button>
              </UpdateWrapper> */}
            </NamedItem>
            <NamedItem>
              <span>Telefon</span>
              {person.phone}
            </NamedItem>
            <hr />
            <ContactText>
              Är det någon av uppgifterna ovan som inte stämmer, vänligen
              kontakta vår kundtjänst på e-post{' '}
              <a href='mailto:info@linforsakring.se'>info@linforsakring.se</a>{' '}
              eller telefon{' '}
              <NoWrap>
                <a href='tel:+46852027969'>08 - 520 279 69</a>
              </NoWrap>
              .
            </ContactText>
          </ProfileWrapper>
        </Main>
        {/* <UpdateEmail
          open={showUpdateEmail}
          currentEmail={'kalle.andersson@eirforsakring.se'}
          hideUpdateEmail={hideUpdateEmail}
        /> */}
      </>
    )
  );
};

export default ProfileView;

const Bold = styled.span`
  font-weight: 500;
`;

const AddressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.2;
`;

const ProfileWrapper = styled(SectionBox)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
`;

const ContactText = styled.p`
  font-size: 0.8rem;
`;

// const UpdateWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;

//   button {
//     background-color: transparent;
//     border: none;
//     color: #252555;
//     font-size: 0.8rem;
//     font-weight: 500;
//     text-decoration: underline;
//     padding: 0;
//     cursor: pointer;
//   }
// `;

const NoWrap = styled.span`
  white-space: nowrap;
`;
