import { memo, useEffect, useState } from 'react';

// import Router from './routes/Router';

import { useCriiptoVerify } from '@criipto/verify-react';
import WebFont from 'webfontloader';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import GlobalStyle from './styles/globalStyle';
import PoppinsFonts from './styles/poppinsFonts';
import LoginView from './views/login/LoginView';

import LoggedInState from './LoggedInState';
import { AppDataProvider } from './context/appDataContext';
import axiosInstance from './api/axiosInstance';

const App = () => {
  const { result } = useCriiptoVerify();

  const [authToken, setAuthToken] = useState<string | null>(null);

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ['Poppins'],
      },
    });
  }, []);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem('authToken');
    };
  }, []);

  useEffect(() => {
    if (authToken) {
      axiosInstance.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${authToken}`;

      // setIsApiReady(true);
    }
  }, [authToken]);

  // useEffect(() => {
  //   if (!isApiReady) {
  //     const test = async () => {
  //       await axiosInstance.get('');
  //     };
  //     test();
  //   }
  // }, [isApiReady]);

  useEffect(() => {
    if (result && (result as { id_token: string }).id_token) {
      setAuthToken((result as { id_token: string }).id_token);
    }
    // if (result && (result as { id_token: string }).id_token) {
    //   sessionStorage.setItem(
    //     'authToken',
    //     (result as { id_token: string }).id_token
    //   );

    //   // setAuthToken((result as { id_token: string }).id_token);
    // }
  }, [result]);

  const { enableLinkTracking, trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, []);

  enableLinkTracking();

  return (
    <>
      <PoppinsFonts />
      <GlobalStyle />
      {authToken ? (
        <AppDataProvider>
          <LoggedInState token={authToken} />
        </AppDataProvider>
      ) : (
        <LoginView />
      )}
    </>
  );
};

export default memo(App);
