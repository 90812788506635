import { memo, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';

import ViewFooter from './components/ViewFooter';

import Menu from './components/Menu';
import HomeView from './views/home/HomeView';
import PolicyView from './views/policy/PolicyView';
import ProfileView from './views/profile/ProfileView';
import TestRedirect from './views/TestRedirect';
import Logout from './views/Logout';
import { HOME, LOGIN, LOGOUT, POLICY, PROFILE } from './routes/ROUTES';
import background from './images/login-bg.jpg';
import { useAppData } from './context/appDataContext';

const LoggedInState = memo(({ token }: { token: string | null }) => {
  // console.log(token);
  const { getPolicies } = useAppData();

  useEffect(() => {
    setTimeout(() => {
      getPolicies && getPolicies();
    }, 300);
  }, [token]);

  const [sessionTimeLeft, setSessionTimeLeft] = useState<number | null>(null);

  //   const setSelectedPolicyByPolicyNumber = (policyNumber: string) => {
  //     if (appData?.policies) {
  //       setSelectedPolicy(
  //         appData.policies.find(policy => policy.policyNumber === policyNumber) ??
  //           null
  //       );
  //     }
  //   };

  // const timeToTokenExipres = setInterval(() => {
  //   if (claims) {
  //     const secondsToExpire = Math.round(
  //       (new Date(claims.exp * 1000).getTime() - new Date().getTime()) / 1000
  //     );

  //     secondsToExpire < 60 && setSessionTimeLeft(secondsToExpire);
  //   }
  // }, 1000);

  return (
    <ViewWrapper>
      {/* {sessionTimeLeft && (
      <div
        style={{
          width: '100%',
          padding: '1rem',
          backgroundColor: 'red',
          color: '#fff',
        }}
      >
        {sessionTimeLeft} sekunder
      </div>
    )} */}
      <BrowserRouter>
        <Menu />
        <BackgroundTest />
        <ContentWrapper>
          <Routes>
            <Route path={HOME} element={<HomeView />} />
            <Route path={`${POLICY}/:policyId`} element={<PolicyView />} />
            <Route path={PROFILE} element={<ProfileView />} />
            <Route path={LOGIN} element={<TestRedirect />} />
            <Route path={LOGOUT} element={<Logout />} />
          </Routes>
        </ContentWrapper>
      </BrowserRouter>
      <ViewFooter />
    </ViewWrapper>
  );
});

export default memo(LoggedInState);

const ViewWrapper = styled.div`
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BackgroundTest = styled.div`
  width: 100%;
  height: 13.5rem;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  right: 0;
  background-image: url(${background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.6;

  @media (min-width: 40rem) {
    height: 18rem;
  }
  /* background: radial-gradient(ellipse at top, #00c2ff, transparent); */

  /* background-size: auto 15rem; */
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding: 0 0.5rem;
  padding-top: 2rem;
`;
