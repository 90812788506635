import styled from 'styled-components';
import Icon from './Icon';

type PopupHeaderProps = {
  children?: JSX.Element | JSX.Element[] | null;
  hidePopup: () => void;
};

const PopupHeader = ({ children, hidePopup }: PopupHeaderProps) => {
  const handleClick = () => hidePopup();

  return (
    <Header>
      {children}
      <Button onClick={handleClick}>
        <Icon iconName='faXmark' />
        <span>Stäng</span>
      </Button>
    </Header>
  );
};

export default PopupHeader;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  padding: 0;
  cursor: pointer;

  span {
    font-size: 0.5rem;
  }
`;
