import NamedItem from '../../../NamedItem';
import { ContentsPolicySpecifics } from '../../../types/policy-types';

type ProductSpecificsContentsProps = {
  contentsPolicySpecifics: ContentsPolicySpecifics;
};

const ProductSpecificsContent = ({
  contentsPolicySpecifics,
}: ProductSpecificsContentsProps) => {
  return (
    <>
      <NamedItem>
        <span>Försäkrat objekt</span>
        {contentsPolicySpecifics.address}
      </NamedItem>
    </>
  );
};

export default ProductSpecificsContent;
