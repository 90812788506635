import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from '../../../components/Icon';
import { POLICY } from '../../../routes/ROUTES';

import SectionBox from '../../../components/SectionBox';
import { Policy } from '../../../types/policy-types';
import formatDate from '../../../utils/formatDate';
import PolicyContentsItemHeader from './PolicyContentsItemHeader';
import PolicyVehicleItemHeader from './PolicyVehicleItemHeader';

type PolicyProps = {
  policy: Policy;
};

const PolicyItem = ({ policy }: PolicyProps) => {
  return (
    <PolicyLink to={`${POLICY}/${policy.policyId}`}>
      <PolicyBox>
        <PolicyHeader>
          <InsuredObjectWrapper>
            {(() => {
              switch (policy.productSpecifics.tag) {
                case 'ContentsPolicy':
                  return (
                    <PolicyContentsItemHeader
                      contentsPolicySpecifics={policy.productSpecifics}
                    />
                  );
                case 'VehiclePolicy':
                  return (
                    <PolicyVehicleItemHeader
                      vehiclePolicySpecifics={policy.productSpecifics}
                    />
                  );
              }
            })()}
          </InsuredObjectWrapper>
          <IconWrapper>
            <Icon iconName='faChevronRight' />
          </IconWrapper>
        </PolicyHeader>
        <main>
          <ProductWrapper>Personbilsföräkring (Hel)</ProductWrapper>
          <PeriodWrapper>
            {formatDate(new Date(policy.startDay))} -{' '}
            {formatDate(new Date(policy.endDay))}
          </PeriodWrapper>
        </main>
      </PolicyBox>
    </PolicyLink>
  );
};

export default PolicyItem;

const PolicyBox = styled(SectionBox)`
  box-shadow: 0px 4px 4px 0px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const PolicyHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InsuredObjectWrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
`;

const IconWrapper = styled.div`
  font-size: 0.8rem;
`;

const ProductWrapper = styled.div`
  font-size: 0.875rem;
`;

const PeriodWrapper = styled.div`
  font-size: 0.75rem;
  color: #a8a8a8;
`;

const PolicyLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
