import styled from 'styled-components';

type ButtonProps = {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void | null;
  size: 'large' | 'medium' | 'small';
  theme?: 'blue' | 'red';
  type?: 'button' | 'submit';
  variant: 'primary' | 'secondary';
};

type ColorProps = {
  backgroundColor: string;
  border: string;
  fontColor: string;
  hover: {
    fontColor?: string;
    backgroundColor: string;
    borderColor: string;
    boxShadow: string;
  };
  textDecoration: string;
};

type StyledButtonProps = {
  themeColors: ColorProps;
  size: {
    fontSize: string;
    padding: string;
  };
};

const Button = ({
  children,
  variant = 'primary',
  theme = 'blue',
  size = 'medium',
  onClick,
  type = 'button',
  disabled = false,
}: ButtonProps) => {
  const colors: {
    [variant: string]: {
      [color: string]: ColorProps;
    };
  } = {
    primary: {
      blue: {
        fontColor: '#fff',
        backgroundColor: '#00c2ff',
        border: 'none',
        textDecoration: 'none',
        hover: {
          backgroundColor: '#00c2ff',
          borderColor: 'inherit',
          boxShadow: 'none',
        },
      },
      red: {
        fontColor: '',
        backgroundColor: '',
        border: '',
        textDecoration: 'none',
        hover: {
          backgroundColor: '',
          borderColor: '',
          boxShadow: '',
        },
      },
    },
    secondary: {
      blue: {
        fontColor: '#00c2ff',
        backgroundColor: 'transparent',
        border: 'none',
        textDecoration: 'underline',
        hover: {
          fontColor: '#00c2ff',
          backgroundColor: 'transparent',
          borderColor: 'none',
          boxShadow: 'none',
        },
      },
      red: {
        fontColor: '#ff4400',
        backgroundColor: 'transparent',
        border: 'none',
        textDecoration: 'underline',
        hover: {
          backgroundColor: '',
          borderColor: '',
          boxShadow: '',
        },
      },
    },
  };

  const sizes: {
    [size: string]: {
      padding: string;
      fontSize: string;
    };
  } = {
    large: {
      padding: '0.6rem',
      fontSize: '1.4rem',
    },
    medium: {
      padding: '0.5rem',
      fontSize: '1rem',
    },
    small: {
      padding: '0.5rem',
      fontSize: '0.75rem',
    },
  };

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <StyledButton
      type={type}
      themeColors={colors[variant][theme]}
      size={sizes[size]}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button<StyledButtonProps>`
  border-radius: 0.25rem;
  line-height: 1;
  background-color: ${({ themeColors }) => themeColors.backgroundColor};
  border: ${({ themeColors }) => themeColors.border};
  outline: none;
  font-weight: 400;
  font-size: ${({ size }) => size.fontSize};
  color: ${({ themeColors }) => themeColors.fontColor};
  padding: ${({ size }) => size.padding};
  text-decoration: ${({ themeColors }) => themeColors.textDecoration};
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
