import { useEffect } from 'react';
import { useCriiptoVerify } from '@criipto/verify-react';
import { useNavigate } from 'react-router-dom';

import { LOGIN } from '../routes/ROUTES';

const Logout = () => {
  const { logout } = useCriiptoVerify();
  const navigate = useNavigate();

  useEffect(() => {
    logout();

    logout({ redirectUri: 'https://mina.linforsakring.se/auth/logout' });
    // navigate(LOGIN);
    // window.location.href();
  }, []);

  return <div>Logout</div>;
};

export default Logout;
