import { useCriiptoVerify } from '@criipto/verify-react';

import PolicyItem from './components/PolicyItem';
import Header from '../../components/Header';
import Main from '../../components/Main';
import { useAppData } from '../../context/appDataContext';

const HomeView = () => {
  const { policies } = useAppData();
  const { claims } = useCriiptoVerify();

  return (
    policies && (
      <>
        <Header>
          <h1>Välkommen!</h1>
          {claims && (
            <h3>
              {claims.given_name} {claims.family_name}
            </h3>
          )}
        </Header>
        <Main>
          {policies.map(policy => (
            <PolicyItem
              key={policy.policyNumber}
              policy={policy}
              // setSelectedPolicy={setSelectedPolicy}
            />
          ))}
        </Main>
      </>
    )
  );
};

export default HomeView;
