import styled from 'styled-components';

const NamedItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  line-height: 1;

  span {
    font-size: 0.75rem;
    color: #707070;
    font-weight: 300;
  }
`;

export default NamedItem;
