import styled from 'styled-components';
import { Payment } from '../../../types/policy-types';
import PaymentItem from './PaymentItem';

type PaymentsProps = {
  payments: Payment[];
};

const Payments = ({ payments }: PaymentsProps) => {
  const nextPayment = payments
    .filter(payment => payment.state.tag === 'PaymentUpcoming')
    .sort((a, b) => a.installmentNumber - b.installmentNumber)[0];

  return (
    <>
      <h2>Betalningar</h2>
      {payments.find(payment => payment.state.tag === 'PaymentPending') && (
        <PaymentsSection>
          <h3>Under behandling</h3>
          {payments
            .filter(payment => payment.state.tag === 'PaymentPending')
            .map(payment => (
              <PaymentItem
                key={payment.installmentNumber}
                payment={payment}
                totalNumberOfPayments={payments.length}
              />
            ))}
        </PaymentsSection>
      )}

      {payments.find(payment => payment.state.tag === 'PaymentFailed') && (
        <PaymentsSection>
          <h3>Misslyckade</h3>
          {payments
            .filter(payment => payment.state.tag === 'PaymentFailed')
            .map(payment => (
              <PaymentItem
                key={payment.installmentNumber}
                payment={payment}
                totalNumberOfPayments={payments.length}
              />
            ))}
        </PaymentsSection>
      )}

      {payments.find(payment => payment.state.tag === 'PaymentUpcoming') && (
        <PaymentsSection>
          <h3>Nästa</h3>
          <PaymentItem
            key={nextPayment.installmentNumber}
            payment={nextPayment}
            totalNumberOfPayments={payments.length}
            isNext={true}
          />
        </PaymentsSection>
      )}
      {payments.filter(payment => payment.state.tag === 'PaymentUpcoming')
        .length > 1 && (
        <PaymentsSection>
          <h3>Kommande</h3>
          {payments
            .filter(
              payment =>
                payment.state.tag === 'PaymentUpcoming' &&
                payment.installmentNumber !== nextPayment.installmentNumber
            )
            .sort((a, b) => a.installmentNumber - b.installmentNumber)
            .map(payment => (
              <PaymentItem
                key={payment.installmentNumber}
                payment={payment}
                totalNumberOfPayments={payments.length}
              />
            ))}
        </PaymentsSection>
      )}
      {payments.find(payment => payment.state.tag === 'PaymentCollected') && (
        <PaymentsSection>
          <h3>Betalt</h3>
          {payments
            .filter(payment => payment.state.tag === 'PaymentCollected')
            .map(payment => (
              <PaymentItem
                key={payment.installmentNumber}
                payment={payment}
                totalNumberOfPayments={payments.length}
              />
            ))}
        </PaymentsSection>
      )}
    </>
  );
};

export default Payments;

const PaymentsSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;
