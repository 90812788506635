import styled from 'styled-components';
import { animated } from 'react-spring';

const SectionBox = styled(animated.section)`
  background-color: #fff;
  border-radius: 0.5rem;
  width: 100%;
  padding: 0.75rem;
  margin: auto;

  hr {
    border-top: 0.0625rem solid #dfecf4;
    border-bottom: none;
  }
`;

export default SectionBox;
