import styled from 'styled-components';
import Icon from '../../../components/Icon';

import SectionBox from '../../../components/SectionBox';
import { IconName } from '../../../types/icon-types';
import { Payment } from '../../../types/policy-types';
import formatDate from '../../../utils/formatDate';

type PaymentItemProps = {
  isNext?: boolean;
  payment: Payment;
  totalNumberOfPayments: number;
};

const PaymentItem = ({
  payment,
  totalNumberOfPayments,
  isNext = false,
}: PaymentItemProps) => {
  const statusSettings: {
    [status: string]: {
      backgroundColor: string;
      fontColor: string;
      icon: IconName;
      label: string;
    };
  } = {
    PaymentCollected: {
      icon: 'faCircleCheck',
      backgroundColor: '#dff0d8',
      fontColor: '#3c763d',
      label: 'Betald',
    },
    PaymentFailed: {
      icon: 'faCircleXmark',
      backgroundColor: '#f8d7da',
      fontColor: '#721c24',
      label: 'Misslyckad',
    },
    PaymentPending: {
      icon: 'faGears',
      backgroundColor: '#fafafa',
      fontColor: '#24292f',
      label: 'Under behandling',
    },
    PaymentUpcoming: {
      icon: 'faCircleDot',
      backgroundColor: '#fafafa',
      fontColor: '#24292f',
      label: 'Kommand',
    },
  };

  return (
    <PolicyCard
      backgroundColor={statusSettings[payment.state.tag].backgroundColor}
      fontColor={statusSettings[payment.state.tag].fontColor}
    >
      <Header>
        <StatusWrapper>
          <Icon iconName={statusSettings[payment.state.tag].icon} />
          {isNext ? 'Nästa' : statusSettings[payment.state.tag].label}{' '}
          {payment.amount.toLocaleString('sv-SE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{' '}
          kr
        </StatusWrapper>
        <span>
          {payment.installmentNumber}/{totalNumberOfPayments}
        </span>
      </Header>
      <Main>
        <span>
          {`Period: ${formatDate(new Date(payment.periodStart))} - ${formatDate(
            new Date(payment.periodEnd)
          )}`}
        </span>
        <span>
          {`Förfallodatum: ${formatDate(
            new Date(payment.state.scheduledCollectionDay)
          )}`}
        </span>
        {payment.state.collectionDay && (
          <span>
            {`Betalningsdatum: ${formatDate(
              new Date(payment.state.collectionDay)
            )}`}
          </span>
        )}
      </Main>
    </PolicyCard>
  );
};

export default PaymentItem;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  margin-bottom: 0.5rem;

  span {
    font-weight: 300;
  }
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const PolicyCard = styled(SectionBox)<{
  backgroundColor: string;
  fontColor: string;
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
  font-size: 0.75rem;
  font-weight: 300;
`;
