import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Squash as Hamburger } from 'hamburger-react';
import { animated, useTransition } from 'react-spring';

import logo from '../images/lin.png';
import MenuItem from './MenuItem';
import { HOME, LOGOUT, PROFILE } from '../routes/ROUTES';

const Menu = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [scrollHeight, setScrollHeight] = useState<number>(0);

  const transition = useTransition(isOpen, {
    from: { transform: 'translateX(-200%)', width: '0' },
    enter: { transform: 'translateX(0%)', width: '100%' },
    leave: { transform: 'translateX(-200%)', width: '0' },
  });

  const getScrollHeight = () => setScrollHeight(window.scrollY);

  useEffect(() => {
    document.addEventListener('scroll', getScrollHeight);

    return () => {
      document.removeEventListener('scroll', getScrollHeight);
    };
  }, []);

  const hideMenu = () => setIsOpen(false);

  return (
    <>
      <MenuWrapper scrollHeight={scrollHeight}>
        <Logo src={logo} alt='lin Försäkring' />
        <WideMenu>
          <MenuItem
            to={HOME}
            text='Hem'
            iconName='faHouse'
            hideMenu={hideMenu}
            fontColor='#252555'
          />
          <MenuItem
            to={PROFILE}
            text='Min profil'
            iconName='faUser'
            hideMenu={hideMenu}
            fontColor='#252555'
          />
          <MenuItem
            to={LOGOUT}
            text='Logga ut'
            iconName='faArrowRightFromBracket'
            hideMenu={hideMenu}
            fontColor='#252555'
          />
        </WideMenu>
        <HamburgerWrapper>
          <Hamburger toggled={isOpen} toggle={setIsOpen} />
        </HamburgerWrapper>
      </MenuWrapper>

      {transition(
        (styles, item) =>
          item && (
            <NavigationWrapper style={styles} open={isOpen}>
              <ul>
                <MenuItem
                  to={HOME}
                  text='Hem'
                  iconName='faHouse'
                  hideMenu={hideMenu}
                  fontColor='#00c2ff'
                />
                <MenuItem
                  to={PROFILE}
                  text='Min profil'
                  iconName='faUser'
                  hideMenu={hideMenu}
                  fontColor='#00c2ff'
                />
                <MenuItem
                  to={LOGOUT}
                  text='Logga ut'
                  iconName='faArrowRightFromBracket'
                  hideMenu={hideMenu}
                  fontColor='#00c2ff'
                />
              </ul>
            </NavigationWrapper>
          )
      )}
    </>
  );
};

export default Menu;

const MenuWrapper = styled.div<{ scrollHeight: number }>`
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  box-shadow: ${({ scrollHeight }) =>
    scrollHeight > 40 ? '0 2px 2px -2px rgba(0, 0, 0, 0.2)' : ''};
  padding: 0.25rem 0.5rem;

  @media (min-width: 40rem) {
    padding: 0.5rem 1rem;
  }
`;

const Logo = styled.img`
  width: 2.5rem;
  height: auto;
`;

const NavigationWrapper = styled(animated.nav)<{ open: boolean }>`
  position: fixed;
  top: 3.5rem;
  overflow: hidden;
  height: calc(100% - 3.5rem);
  background-color: #fff;

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem 1rem;
  }
`;

const WideMenu = styled.ul`
  list-style: none;
  display: flex;
  gap: 1.5rem;
  color: #fff;
  /* flex: 1; */

  @media (max-width: 40rem) {
    display: none;
  }
`;

const HamburgerWrapper = styled.div`
  @media (min-width: 40rem) {
    display: none;
  }
`;
