import NamedItem from '../../../NamedItem';
import { VehiclePolicySpecifics } from '../../../types/policy-types';

type ProductSpecificsVehicleProps = {
  vehiclePolicySpecifics: VehiclePolicySpecifics;
};

const ProductSpecificsVehicle = ({
  vehiclePolicySpecifics,
}: ProductSpecificsVehicleProps) => {
  return (
    <>
      <NamedItem>
        <span>Försäkrat objekt</span>
        {vehiclePolicySpecifics.registrationNumber.toLocaleUpperCase()}
      </NamedItem>
      <NamedItem>
        <span>Produkt</span>
        Personbilsförsäkring
      </NamedItem>
      <NamedItem>
        <span>Omfattning</span>
        {vehiclePolicySpecifics.coverLevel}
      </NamedItem>
      <NamedItem>
        <span>Årlig körsträcka</span>
        {vehiclePolicySpecifics.annualMilage.kmMin.toLocaleString(
          'sv-SE'
        )} - {vehiclePolicySpecifics.annualMilage.kmMax.toLocaleString('sv-SE')}{' '}
        mil
      </NamedItem>
      <NamedItem>
        <span>Hyrbil</span>
        {vehiclePolicySpecifics.rentalCarIncluded ? 'Ja' : 'Nej'}
      </NamedItem>
      <NamedItem>
        <span>Djurkollision</span>
        {vehiclePolicySpecifics.animalCollisionIncluded ? 'Ja' : 'Nej'}
      </NamedItem>
      <NamedItem>
        <span>Förare under 25 år</span>
        {vehiclePolicySpecifics.youngDriverCovered ? 'Ja' : 'Nej'}
      </NamedItem>
    </>
  );
};

export default ProductSpecificsVehicle;
