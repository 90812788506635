import styled from 'styled-components';

const ViewFooter = () => {
  return (
    <Footer>
      <ContentWrapper>
        <a
          href='https://www.linforsakring.se/integritetspolicy'
          target='_blank'
          rel='noreferrer'
        >
          Integritetspolicy
        </a>
        <AddressBlock>
          <span>Försäkringsgivare:</span>
          <span>Eir Försäkring AB, org. 559166-0617</span>
          <span>Norrlandsgatan 12 111 43 Stockholm, Sweden</span>
        </AddressBlock>
      </ContentWrapper>
    </Footer>
  );
};

export default ViewFooter;

const Footer = styled.footer`
  background-color: #fff;
  color: #252555;
  margin-top: 3rem;
  padding: 2rem 0.5rem;
  font-size: 0.6875rem;

  @media (min-width: 40rem) {
    padding: 2rem;
  }
`;

const AddressBlock = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 0.0625rem solid #252555;
  padding-top: 2rem;
`;
