import { createGlobalStyle } from 'styled-components';

import PoppinsBlack from './Poppins-Black.ttf';
import PoppinsBlackItalic from './Poppins-BlackItalic.ttf';
import PoppinsBold from './Poppins-Bold.ttf';
import PoppinsBoldItalic from './Poppins-BoldItalic.ttf';
import PoppinsExtraBold from './Poppins-ExtraBold.ttf';
import PoppinsExtraBoldItalic from './Poppins-ExtraBoldItalic.ttf';
import PoppinsExtraLight from './Poppins-ExtraLight.ttf';
import PoppinsExtraLightItalic from './Poppins-ExtraLightItalic.ttf';
import PoppinsItalic from './Poppins-Italic.ttf';
import PoppinsLight from './Poppins-Light.ttf';
import PoppinsLightItalic from './Poppins-LightItalic.ttf';
import PoppinsMedium from './Poppins-Medium.ttf';
import PoppinsMediumItalic from './Poppins-MediumItalic.ttf';
import PoppinsRegular from './Poppins-Regular.ttf';
import PoppinsSemiBold from './Poppins-SemiBold.ttf';
import PoppinsSemiBoldItalic from './Poppins-SemiBoldItalic.ttf';
import PoppinsThin from './Poppins-Thin.ttf';
import PoppinsThinItalic from './Poppins-ThinItalic.ttf';

export default createGlobalStyle`
@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(${PoppinsThinItalic}) format('truetype');
  font-weight: 100;
  font-style: italic;
}  

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsThin}) format('truetype');
    font-weight: 100;
    font-style: normal;
  } 
  
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsExtraLight}) format('truetype');
    font-weight: 200;
    font-style: normal;
  } 

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsExtraLightItalic}) format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsLight}) format('truetype');
    font-weight: 300;
    font-style: normal;
  } 
  
  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsLightItalic}) format('truetype');
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsRegular}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsItalic}) format('truetype');
    font-weight: 400;
    font-style: italic;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsMedium}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsMediumItalic}) format('truetype');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsSemiBold}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsSemiBoldItalic}) format('truetype');
    font-weight: 600;
    font-style: italic;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsBold}) format('truetype');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsBoldItalic}) format('truetype');
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsExtraBold}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsExtraBoldItalic}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsBlack}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(${PoppinsBlackItalic}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }
`;
