import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
* {
  outline: none;
  box-sizing: border-box;
  margin: 0;
}

html {
  height: 100%;
}

html, input, select, textarea, button {
  font-family: Poppins;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  color: #252555;
}

body {
 background-color: #F5EFE5;
 font-size: 16px;
 padding: 0;
 margin: 0;
 height: 100%;
 
}

h1, h2, h3 {
  font-weight: 600;
}

h1 {
  font-size: 2.5rem
  ;
}

a {
  color: #00c2ff;
}

select, textarea, input[type='text'] {
  font-size: inherit;
  padding: 0.4rem;
  border-radius: 0.25rem;
  outline: none;
  border: 0.0625rem solid #252555;

  &:focus {
    outline:1px solid #252555;
  }
}

#root {
  height: 100%;
}



`;

export default GlobalStyle;
